<template>
    <div>
        <a-modal title="导入" :visible="dialog" :footer="null" @cancel="dialog=false">
        <span>
            <input ref="upload" style="display: none" type="file" @change="onUpload" />

            <a-button :loading="loading" @click="onClick" style="margin-right: 10px;"
            >导入</a-button>
            <a href="javacript:void(0)" @click="download">下载模板</a>
        </span>
        </a-modal>
        <Pane />
        <a-card class="container">
            <a-row style="margin-bottom: 10px;">
                <a-col :span="22">
                <a-form-model layout="inline">

                    <a-form-model-item>
                        <a-input v-model="form.projectName" placeholder="项目名称" style="width: 150px"></a-input>
                    </a-form-model-item>
                        
                    <a-form-model-item>
                        <a-select style="width: 150px;" v-model="form.type" placeholder="类型">
                            <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item>
                        <a-select
                            placeholder="部门"
                            v-model="form.deptId"
                            show-search
                            style="width: 160px"
                            :filter-option="$selectFilterOption"
                        >
                            <a-select-option
                            v-for="item in organizationList"
                            :key="item.id"
                            :value="item.id"
                            >{{ item.uniqueName }}</a-select-option
                            >
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-space>
                            <a-button @click="query" type="primary">查询</a-button>
                            <a-button @click="reset">重置</a-button>
                        </a-space>
                    </a-form-model-item>
                </a-form-model>
                </a-col>
                <a-col :span="2" v-if="$getPermission($route.path + '/add')">
                    <div class="right" style="margin-top: 4px">
                        <a-button @click="dialog=true" style="margin-right: 10px;"
                        >导入</a-button>
                        <a-button @click="$router.push($route.path + '/add')" type="primary"
                        >新增</a-button
                        >
                    </div>
                </a-col>
            </a-row>
            <a-table bordered :dataSource="tableList" :loading="loading" @change="onChange" :pagination="{
                total,
                current,
                pageSize,
                showTotal: (total) => `共 ${total} 条记录`,
            }" rowKey="id">
                    <a-table-column title="项目名" data-index="projectName"></a-table-column>
                    <a-table-column title="部门" data-index="deptName"></a-table-column>
                    <a-table-column title="招标人" data-index="bidUserName"></a-table-column>
                    <a-table-column title="经办人" data-index="operatorName"></a-table-column>
                    <a-table-column title="待回款金额">
                        <template slot-scope="text, record, index">
                            {{ text.collectedAmount }}万元
                        </template>
                    </a-table-column>
                    <a-table-column title="付款金额">
                        <template slot-scope="text, record, index">
                            {{ text.payAmount }}万元
                        </template>
                    </a-table-column>
                    <a-table-column title="付款日期" data-index="payDate"></a-table-column>
                    <a-table-column title="类型">
                        <template slot-scope="text, record, index">
                            <DataDictFinder
                                dictType="ds_bond_type"
                                :dictValue="text.type"
                            />
                        </template>
                    </a-table-column>
                    <a-table-column align="center" title="操作" width="200px" fixed="right">
                        <template slot-scope="text">
                            <a-space>
                                <a
                                    href="#"
                                    v-if="$getPermission($route.path + '/edit')"
                                    @click.prevent="
                                    $router.push($route.path + '/edit?id=' + text.id)
                                    "
                                    >编辑</a
                                >
                                <a
                                    href="#"
                                    v-if="$getPermission($route.path + '/edit')"
                                    @click.prevent="deleteText(text)"
                                >删除</a>
                            </a-space>
                        </template>
                        </a-table-column>
            </a-table>
        </a-card>
    </div>
</template>
<script>
import request from "@/api/request";
import { mapGetters } from "vuex";
import download from '@/api/download'
import { saveAs } from "file-saver";
function importChangeList(data) {
    return request({
        url: "project-service/dsBond/import",
        method: 'post',
        data
    });
}
function getTemplate() {
  return download({
    url: "/project-service/dsBond/importTemplate",
  });
}
function fetchList(params) {
    return request({
        url: "project-service/dsBond/list",
        params: params
    });
}
function remove(id) {
  return request({
    url: "project-service/dsBond/del/" + id,
  });
}
function getOrganization() {
    return request({
        url: "user-service/org/listForPrd",
    });
}
export default {
    computed: {
        ...mapGetters("setting", ["findDataDict"]),
        ...mapGetters("auth", ["isHr"]),
        typeList() {
            return this.findDataDict("ds_bond_type");
        },
    },
    data() {
        return {
            dialog:false,
            yearList: [],
            form: {
                deptId: '',
                year: ''
            },
            now: 0,
            current: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            total: 0,
            tableList: [],
            organizationList: []
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id,year } = query || {};
        this.form.deptId = id
        const now = new Date().getFullYear()
        for (let index = 2021; index <= now; index++) {
            this.yearList.push(
                {
                    name: index,
                    value: index
                }
            )
        }
        this.form.year = year
        getOrganization().then(v=>{
            this.organizationList = v
        })
        this.getList()
    },
    methods: {
        download(){
            getTemplate().then(blob=>{
                saveAs(blob, "导出结果.xlsx");
            })
        },
        onClick(){
            this.$refs.upload.click();
        },
        onUpload(e) {
            console.log(e)
            const files = e.target.files;
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("file", files[i]);
            }

            this.loading = true;

            importChangeList(data)
                .then(() => {
                })
                .finally(() => {
                this.loading = false;
                e.target.value = null;
                });
        },
        deleteText(text) {
        const that = this;
        this.$confirm({
            title: "确认要删除吗？",
            onOk() {
            remove(text.id).then(() => {
                that.getList();
            });
            }
        });
        },
        query() {
            this.current = 1;
            this.getList();
        },
        reset() {
            this.form = {};
            this.getList();
        },
        getList(){
            this.loading = true
            fetchList({deptId: this.form.deptId, projectName:this.form.projectName, typeName:this.form.type,pageNum:this.current,pageSize:this.pageSize,year:this.form.year}).then(res => {
                this.tableList = res.list
                this.total = res.totalSize
            }).finally(() => {
                this.loading = false
            })
        },
        onChange(pagination) {
            this.current = pagination.current;
            this.pageSize = pagination.pageSize;
            this.getList()
        },
        showDialog() {
            this.getList();
        }
    }
}
</script>
<style>
.selectNoBorder .ant-select-selection{
  border: none;background: none;color: rgba(0, 0, 0, 0.65);
}
.selectNoBorder .ant-select-arrow{
  display: none;
}
.selectNoBorder .ant-select-selection__rendered{
  margin: 0;
}
</style>